<template>
  <b-container class="pl-0 pr-0 facilities-container">
    <b-row>
      <b-col :order-md="textOrder" cols="12" md="5" class="text-center my-auto">
        <h3>{{ title }}</h3>
        <p><slot></slot></p>
      </b-col>
      <b-col
        :order-md="imageOrder"
        cols="12"
        md="7"
        class="text-center my-auto"
      >
        <silent-box :gallery="gallery"></silent-box>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "FacilitiesItem",

  props: {
    title: String,
    reverse: Boolean,
    gallery: Array
  },

  computed: {
    textOrder: function() {
      return this.reverse ? 2 : 1;
    },
    imageOrder: function() {
      return this.reverse ? 1 : 2;
    }
  }
};
</script>

<style scoped>
.facilities-container {
  margin-bottom: 3rem;
}

::v-deep .silentbox-item {
  width: 50%;
  max-width: 240px;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
}

::v-deep .silentbox-item img {
  width: 100%;
  border-radius: 0.5rem;
}

::v-deep #silentbox-overlay__embed {
  width: 85%;
}

::v-deep #silentbox-overlay__arrow-buttons .arrow {
  height: 2.5rem;
  width: 2.5rem;
}

::v-deep #silentbox-overlay__arrow-buttons .arrow-next {
  top: 80%;
}

::v-deep #silentbox-overlay__arrow-buttons .arrow-previous {
  top: 80%;
}
</style>
