<template>
  <b-container fluid class="main-container">
    <b-row>
      <b-col md="8" offset-md="2" class="main-col">
        <h1>{{ $t("booking.title") }}</h1>
        <p class="important-text mb-4">{{ $t("booking.description") }}</p>
        <b-row>
          <b-col class="pr-5">
            <BookingForm class="mb-5" />
          </b-col>
          <b-col class="d-none d-lg-block align-self-center">
            <b-img
              :src="images.landscape"
              id="contact-image"
              class="d-none d-md-block mx-auto"
              alt="Flamencos comiendo en el Delta del Ebro"
            />
          </b-col>
        </b-row>
        <p>{{ $t("booking.contact-methods") }}</p>
        <b-row align-h="center" class="mb-5">
          <b-col cols="3" class="my-auto text-right">
            <b-img
              :src="images.contact"
              id="contact-icon"
              alt="libreta de direcciones"
            >
            </b-img>
          </b-col>
          <b-col cols="9">
            {{ $t("booking.contact-phone") }}
            +34 634 56 42 07 <br />{{ $t("booking.contact-email") }}
            <b-link href="mailto:reservas@lascarolinas.net">
              reservas@lascarolinas.net
            </b-link>
          </b-col>
        </b-row>
        <h2>{{ $t("booking.cancel-title") }}</h2>
        <p class="mb-4">{{ $t("booking.cancel-details") }}</p>
        <h2 class="mb-4">{{ $t("booking.acknowledgments") }}</h2>
        <b-row align-h="around">
          <b-col cols="auto" class="mb-2">
            <b-link href="https://www.ebre.com">
              <b-img
                :src="images.park"
                class="logo-image"
                alt="Delta del Ebro Parque Natural"
              />
            </b-link>
          </b-col>
          <b-col cols="auto" class="mb-2">
            <b-link href="https://turisme.larapita.cat/es/node/297">
              <b-img
                :src="images.rapita"
                class="logo-image"
                alt="La Rapita Turisme"
              />
            </b-link>
          </b-col>

          <b-col cols="auto" class="mb-2">
            <b-link href="http://act.gencat.cat/">
              <b-img
                :src="images.gencat"
                class="logo-image"
                alt="Generalitat de Catalunya"
              />
            </b-link>
          </b-col>

          <b-col cols="auto" class="mb-2">
            <b-link href="www.deltacleta.cat">
              <b-img
                :src="images.deltacleta"
                class="logo-image"
                alt="Deltacleta"
              />
            </b-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import BookingForm from "@/components/BookingForm.vue";

export default {
  name: "Booking",

  metaInfo: {
    title: "Las Carolinas | Reserva tu apartamento en el Delta del Ebro",

    link: [
      {
        rel: "alternate",
        hreflang: "es",
        href: "https://www.lascarolinas.net/es/booking"
      },
      {
        rel: "alternate",
        hreflang: "ca",
        href: "https://www.lascarolinas.net/ca/booking"
      },
      {
        rel: "alternate",
        hreflang: "en",
        href: "https://www.lascarolinas.net/en/booking"
      },
      {
        rel: "alternate",
        hreflang: "fr",
        href: "https://www.lascarolinas.net/fr/booking"
      }
    ],

    meta: [
      {
        name: "description",
        content:
          "¡Reserve un apartamento directamente desde la página web y ven a conocer el Delta del Ebro! ¡También con piscina privada!"
      }
    ]
  },

  components: {
    BookingForm
  },

  data: function() {
    return {
      images: {
        landscape: require("@/assets/booking/image5.jpg"),
        contact: require("@/assets/booking/contact_icon.png"),
        rapita: require("@/assets/booking/rapita_logo.png"),
        park: require("@/assets/booking/park_logo.png"),
        gencat: require("@/assets/booking/gencat_logo.png"),
        deltacleta: require("@/assets/booking/deltacleta_logo.png")
      }
    };
  }
};
</script>

<style scoped>
#contact-image {
  width: 90%;
}

#contact-icon {
  width: 2.5em;
}

.logo-image {
  height: 3.5em;
}
</style>
