var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"id":"booking-form"}},[_c('b-row',[_c('b-col',{staticClass:"pr-0 pl-0"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendForm)}}},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('booking.form-name')}},[_c('b-form-input',{attrs:{"type":"text","name":"name"},on:{"change":_vm.inputName},model:{value:(_vm.contactName),callback:function ($$v) {_vm.contactName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"contactName"}})],1),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('booking.form-contact')}},[_c('b-form-input',{attrs:{"type":"text","name":"contact"},model:{value:(_vm.contactAddress),callback:function ($$v) {_vm.contactAddress=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"contactAddress"}})],1),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('b-form-group',{attrs:{"label":_vm.$t('booking.form-phone')}},[_c('b-form-input',{attrs:{"type":"text","name":"phone"},model:{value:(_vm.contactPhone),callback:function ($$v) {_vm.contactPhone=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"contactPhone"}})],1),_c('ValidationProvider',{attrs:{"name":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('booking.form-message')}},[_c('b-form-textarea',{attrs:{"id":"textarea","rows":"3","max-rows":"6"},model:{value:(_vm.contactMessage),callback:function ($$v) {_vm.contactMessage=$$v},expression:"contactMessage"}})],1),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"requiredPolicy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{staticClass:"mb-2",attrs:{"value":"accepted","unchecked-value":""},model:{value:(_vm.contactPrivacy),callback:function ($$v) {_vm.contactPrivacy=$$v},expression:"contactPrivacy"}},[_vm._v(_vm._s(_vm.$t("booking.form-policy")))]),_c('span',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(!_vm.contactSent && !_vm.contactError)?_c('b-button',{staticClass:"carolinas-button float-right",attrs:{"id":"send-button","type":"submit"}},[_vm._v(_vm._s(_vm.$t("booking.form-send")))]):_vm._e()],1)]}}])}),_c('b-alert',{staticClass:"text-center",attrs:{"variant":"success","show":_vm.contactSent}},[_vm._v(_vm._s(_vm.$t("booking.sent-message")))]),_c('b-alert',{staticClass:"text-center",attrs:{"variant":"danger","show":_vm.contactError}},[_vm._v(_vm._s(_vm.$t("booking.sending-error")))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }