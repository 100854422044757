<template>
  <b-container id="booking-form">
    <b-row>
      <b-col class="pr-0 pl-0">
        <ValidationObserver v-slot="{ handleSubmit }" ref="form">
          <form @submit.prevent="handleSubmit(sendForm)">
            <ValidationProvider
              name="name"
              rules="required|alpha_spaces"
              v-slot="{ errors }"
            >
              <b-form-group :label="$t('booking.form-name')">
                <b-form-input
                  type="text"
                  name="name"
                  v-model.trim="contactName"
                  @change="inputName"
                />
              </b-form-group>
              <span class="form-error">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider
              name="address"
              rules="required|email"
              v-slot="{ errors }"
            >
              <b-form-group :label="$t('booking.form-contact')">
                <b-form-input
                  type="text"
                  name="contact"
                  v-model.trim="contactAddress"
                />
              </b-form-group>
              <span class="form-error">{{ errors[0] }}</span>
            </ValidationProvider>
            <b-form-group :label="$t('booking.form-phone')">
              <b-form-input
                type="text"
                name="phone"
                v-model.trim="contactPhone"
              />
            </b-form-group>
            <ValidationProvider
              name="message"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group :label="$t('booking.form-message')">
                <b-form-textarea
                  id="textarea"
                  rows="3"
                  max-rows="6"
                  v-model="contactMessage"
                ></b-form-textarea>
              </b-form-group>
              <span class="form-error">{{ errors[0] }}</span>
            </ValidationProvider>
            <ValidationProvider rules="requiredPolicy" v-slot="{ errors }">
              <b-form-checkbox
                value="accepted"
                unchecked-value
                v-model="contactPrivacy"
                class="mb-2"
                >{{ $t("booking.form-policy") }}</b-form-checkbox
              >
              <span class="form-error">{{ errors[0] }}</span>
            </ValidationProvider>
            <b-button
              v-if="!contactSent && !contactError"
              class="carolinas-button float-right"
              id="send-button"
              type="submit"
              >{{ $t("booking.form-send") }}</b-button
            >
          </form>
        </ValidationObserver>
        <b-alert variant="success" :show="contactSent" class="text-center">{{
          $t("booking.sent-message")
        }}</b-alert>
        <b-alert variant="danger" :show="contactError" class="text-center">{{
          $t("booking.sending-error")
        }}</b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import "@/validation.js";

export default {
  name: "BookingForm",

  components: {
    ValidationProvider,
    ValidationObserver
  },

  data: function() {
    return {
      contactName: "",
      contactAddress: "",
      contactPhone: "",
      contactMessage: this.$route.query.description,
      contactPrivacy: "",
      contactSent: false,
      contactError: false
    };
  },

  methods: {
    inputName() {
      this.$gtag.event("FormStarted", {
        event_category: "Contact",
        event_label: "BookingPage"
      });
    },

    sendForm() {
      this.axios
        .post(process.env.VUE_APP_ROOT_API + "/website/contact-form", {
          name: this.contactName,
          address: this.contactAddress,
          phone: this.contactPhone,
          message: this.contactMessage,
          privacy: this.contactPrivacy
        })
        .then(response => {
          this.contactName = "";
          this.contactAddress = "";
          this.contactPhone = "";
          this.contactMessage = "";
          this.contactPrivacy = "";

          this.$nextTick(() => {
            this.$refs.form.reset();
          });
          this.contactSent = true;

          this.$gtag.event("FormSent", {
            event_category: "Contact",
            event_label: "BookingPage"
          });
        })
        .catch(error => {
          this.contactError = true;
        });
    }
  }
};
</script>

<style scoped>
.form-error {
  color: red;
}

#send-button {
  padding-left: 2rem;
  padding-right: 2rem;
}
</style>
