<template>
  <b-container fluid class="main-container">
    <b-row>
      <b-col md="8" offset-md="2" class="main-col">
        <h1>{{ $t("apartments.title") }}</h1>
        <b-row class="mb-4" align-h="between">
          <b-col cols="auto" class="mb-2">
            {{ $t("apartments.filter-capacity") }}
            <b-form-select
              v-model="minCapacity"
              :options="capacityOptions"
              class="property-selector"
              @change="searchApartment"
            ></b-form-select>
          </b-col>
          <b-col cols="auto" class="mb-2">
            {{ $t("apartments.filter-floor") }}
            <b-form-checkbox-group
              v-model="floorsSelected"
              :options="floorsOptions"
              buttons
              id="floor-buttons"
              @change="searchApartment"
            ></b-form-checkbox-group>
          </b-col>
          <b-col cols="auto" class="mb-2">
            {{ $t("apartments.filter-balconies") }}
            <b-form-select
              v-model="minBalconies"
              :options="balconiesOptions"
              class="property-selector"
              @change="searchApartment"
            ></b-form-select>
          </b-col>
          <b-col cols="auto" class="my-auto mb-2">
            {{ $t("apartments.filter-solarium") }}
            <b-form-checkbox
              v-model="onlySolarium"
              switch
              id="solarium-switch"
              @change="searchApartment"
            ></b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col class="p-0">
            <div
              v-for="apartment in slicedApartments"
              :key="apartment.id"
              class="mb-3"
            >
              <ApartmentsItem
                :title="apartment.title"
                :gallery="apartment.gallery"
                :capacity="apartment.capacity"
                :beds="apartment.beds"
                :bedrooms="apartment.bedrooms"
                :balconies="apartment.balconies"
                :adapted="apartment.adapted"
                :solarium="apartment.solarium"
                :description="apartment.description"
                :lowSeasonFare="apartment.lowSeasonFare"
                :midSeasonFare="apartment.midSeasonFare"
                :highSeasonFare="apartment.highSeasonFare"
              />
            </div>
          </b-col>
        </b-row>
        <b-row v-show="paginationDisplayed">
          <b-col>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalApartments"
              :per-page="apartmentsPerPage"
              align="center"
              pills
              hide-goto-end-buttons
              id="page-selector"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import apartmentsFeatures from "@/data/apartmentFeatures.json";
import apartmentFares from "@/data/apartmentFares.json";
import apartmentGalleries from "@/data/apartmentGalleries.js";
import ApartmentsItem from "@/components/ApartmentsItem.vue";

export default {
  name: "Apartments",

  components: {
    ApartmentsItem
  },

  metaInfo: {
    title: "Las Carolinas | Encuentra tu apartamento, conoce el Delta del Ebro",

    link: [
      {
        rel: "alternate",
        hreflang: "es",
        href: "https://www.lascarolinas.net/es/apartamentos"
      },
      {
        rel: "alternate",
        hreflang: "ca",
        href: "https://www.lascarolinas.net/ca/apartamentos"
      },
      {
        rel: "alternate",
        hreflang: "en",
        href: "https://www.lascarolinas.net/en/apartamentos"
      },
      {
        rel: "alternate",
        hreflang: "fr",
        href: "https://www.lascarolinas.net/fr/apartamentos"
      }
    ],

    meta: [
      {
        name: "description",
        content:
          "Con piscina privada, para seis personas, con balcón o adaptado a movilidad reducida. ¡Encuentra tu apartamento y ven a conocer el Delta del Ebro!"
      }
    ]
  },

  data: function() {
    return {
      apartmentsFeatures: apartmentsFeatures.data,
      apartmentGalleries: apartmentGalleries.types,

      apartmentsPerPage: 5,
      currentPage: 1,

      minCapacity: 4,
      capacityOptions: [
        { text: "4+", value: 4 },
        { text: "6", value: 6 }
      ],

      floorsSelected: [0, 1, 4],

      minBalconies: 0,
      balconiesOptions: [
        { text: "0+", value: 0 },
        { text: "1+", value: 1 },
        { text: "2", value: 2 }
      ],

      onlySolarium: false
    };
  },

  methods: {
    searchApartment: function() {
      this.$gtag.event("SearchApartment", {
        event_category: "SearchApartment",
        event_label: "ApartmentsPage"
      });
    }
  },

  computed: {
    floorsOptions: function() {
      return [
        { text: this.$t("apartments.filter-floor-ground"), value: 0 },
        { text: this.$t("apartments.filter-floor-middle"), value: 1 },
        { text: this.$t("apartments.filter-floor-top"), value: 4 }
      ];
    },

    galleriedTextedApartments: function() {
      for (let index = 0; index < this.apartmentsFeatures.length; index++) {
        let element = this.apartmentsFeatures[index];
        element.gallery = apartmentGalleries[element.galleryType];
        element.title = this.$t(element.titleType);
        element.description = this.$t(element.descriptionType);
      }
      return this.apartmentsFeatures;
    },

    pricedApartments: function() {
      for (
        let index = 0;
        index < this.galleriedTextedApartments.length;
        index++
      ) {
        let element = this.galleriedTextedApartments[index];
        element.lowSeasonFare =
          apartmentFares.types[element.fareType].lowSeason;
        element.midSeasonFare =
          apartmentFares.types[element.fareType].midSeason;
        element.highSeasonFare =
          apartmentFares.types[element.fareType].highSeason;
      }
      return this.galleriedTextedApartments;
    },

    filteredApartments: function() {
      let minCapacity = this.minCapacity;
      let floorsSelected = this.floorsSelected;
      let minBalconies = this.minBalconies;
      let onlySolarium = this.onlySolarium;

      let filteredApartments = this.pricedApartments.filter(function(
        apartment
      ) {
        return (
          minCapacity <= apartment.capacity &&
          floorsSelected.includes(apartment.floor) &&
          minBalconies <= apartment.balconies &&
          !onlySolarium | apartment.solarium
        );
      });

      return filteredApartments;
    },

    slicedApartments: function() {
      let slicedApartments = this.filteredApartments.slice(
        (this.currentPage - 1) * this.apartmentsPerPage,
        this.currentPage * this.apartmentsPerPage
      );

      return slicedApartments;
    },

    totalApartments: function() {
      return this.filteredApartments.length;
    },

    paginationDisplayed() {
      return this.filteredApartments.length > this.apartmentsPerPage;
    }
  }
};
</script>

<style scoped>
.property-selector {
  width: auto;
  margin-left: 1rem;
}

.custom-switch {
  display: inline-block;
  margin-left: 1rem;
}

#floor-buttons {
  margin-left: 1rem;
}

::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #68a26e;
  border-color: #68a26e;
}

::v-deep #floor-buttons .btn-secondary.active {
  color: white;
  border: 1px solid #ced4da;
  background-color: #68a26e;
}

::v-deep #floor-buttons .btn-secondary {
  color: #495057;
  border: 1px solid #ced4da;
  background-color: white;
}

::v-deep #page-selector .page-link {
  color: #495057;
}

::v-deep #page-selector .page-item.active .page-link {
  background-color: #68a26e;
  border-color: #68a26e;
  color: white;
}
</style>
