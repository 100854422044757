import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/i18n";
import { mainLocale } from "@/i18n";
import { supportedLanguages } from "@/i18n";
import Root from "@/views/Root.vue";
import LandingCoronavirus from "@/views/LandingCoronavirus.vue";
import Location from "@/views/Location.vue";
import Facilities from "@/views/Facilities.vue";
import Disabled from "@/views/Disabled.vue";
import Apartments from "@/views/Apartments.vue";
import Booking from "@/views/Booking.vue";
import Coronavirus from "@/views/Coronavirus.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import CookiesPolicy from "@/views/CookiesPolicy.vue";
import LegalNotice from "@/views/LegalNotice.vue";
import NotFound from "@/views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LandingCoronavirus",
    component: LandingCoronavirus
  },
  {
    path: "/delta-ebro",
    name: "Location",
    component: Location
  },
  {
    path: "/location",
    redirect: "/delta-ebro"
  },
  {
    path: "/instalaciones/",
    component: Root,
    children: [
      {
        path: "",
        name: "Facilities",
        component: Facilities
      },
      {
        path: "movilidad-reducida",
        name: "Disabled",
        component: Disabled
      }
    ]
  },
  {
    path: "/facilities",
    redirect: "/instalaciones"
  },
  {
    path: "/apartamentos",
    name: "Apartments",
    component: Apartments
  },
  {
    path: "/apartments",
    redirect: "/apartamentos"
  },
  {
    path: "/booking",
    name: "Booking",
    component: Booking
  },
  {
    path: "/coronavirus",
    name: "Coronavirus",
    component: Coronavirus
  },
  {
    path: "/privacidad",
    name: "Privacy",
    component: PrivacyPolicy
  },
  {
    path: "/privacy",
    redirect: "/privacidad"
  },
  {
    path: "/legal",
    name: "Legal",
    component: LegalNotice
  },
  {
    path: "/cookies",
    name: "Cookies",
    component: CookiesPolicy
  },
  {
    path: "/404",
    component: NotFound
  },
  {
    path: "*",
    redirect: "/"
  },
  {
    path: `/:lang(${supportedLanguages.join("|")})?/`,
    component: Root,
    children: [
      {
        path: "",
        name: "TranslatedLandingCoronavirus",
        component: LandingCoronavirus
      },
      {
        path: "delta-ebro",
        name: "TranslatedLocation",
        component: Location
      },
      {
        path: "location",
        redirect: "delta-ebro"
      },
      {
        path: "instalaciones/",
        component: Root,
        children: [
          {
            path: "",
            name: "TranslatedFacilities",
            component: Facilities
          },
          {
            path: "movilidad-reducida",
            name: "TranslatedDisabled",
            component: Disabled
          }
        ]
      },
      {
        path: "facilities",
        redirect: "instalaciones"
      },
      {
        path: "facilities/disabled",
        redirect: "instalaciones/movilidad-reducida"
      },
      {
        path: "apartamentos",
        name: "TranslatedApartments",
        component: Apartments
      },
      {
        path: "apartments",
        redirect: "apartamentos"
      },
      {
        path: "booking",
        name: "TranslatedBooking",
        component: Booking
      },
      {
        path: "coronavirus",
        name: "TranslatedCoronavirus",
        component: Coronavirus
      },
      {
        path: "privacidad",
        name: "TranslatedPrivacy",
        component: PrivacyPolicy
      },
      {
        path: "privacy",
        redirect: "privacidad"
      },
      {
        path: "legal",
        name: "TranslatedLegal",
        component: LegalNotice
      },
      {
        path: "cookies",
        name: "TranslatedCookies",
        component: CookiesPolicy
      },
      {
        path: "404",
        component: NotFound
      },
      {
        path: "*",
        redirect: "/:lang"
      }
    ]
  }
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.params.lang === mainLocale) {
    // If /es in url, remove it and translate
    i18n.locale = mainLocale;
    let stripped = to.fullPath.replace("/" + mainLocale, "");
    if (!stripped) {
      stripped = "/";
    }
    next({ path: stripped });
  } else if (!to.params.lang && i18n.locale !== mainLocale) {
    // If lang is not set in url and default lang is not "es", put lang in the url.
    to.params.lang = i18n.locale;
    const redirectTo = `/${to.params.lang}${to.fullPath}`;
    next({ path: redirectTo });
  } else if ("lang" in to.params && to.params.lang !== i18n.locale) {
    // If there is lang in the url and it does not match the current one, translate the text.
    i18n.locale = to.params.lang;
    next();
  } else {
    next();
  }
});

export default router;
