const types = {
  F0D1: [
    {
      src: require("@/assets/apartments/f0d1/bedroom_1.jpg"),
      thumbnail: require("@/assets/apartments/f0d1/bedroom_1_tn.jpg"),
      description: "Bedroom",
      alt: "comodo dormitorio doble"
    },
    {
      src: require("@/assets/apartments/f0d1/kitchen_1.jpg"),
      thumbnail: require("@/assets/apartments/f0d1/kitchen_1_tn.jpg"),
      description: "cocina completamente equipada",
      alt: "cocina completamente equipada"
    },
    {
      src: require("@/assets/apartments/f0d1/living_1.jpg"),
      thumbnail: require("@/assets/apartments/f0d1/living_1_tn.jpg"),
      description: "Living",
      alt: "salon comedor con television"
    },
    {
      src: require("@/assets/apartments/f0d1/terrace_1.jpg"),
      thumbnail: require("@/assets/apartments/f0d1/terrace_1_tn.jpg"),
      description: "Terrace",
      alt: "terraza con vistas"
    },
    {
      src: require("@/assets/apartments/f0d1/bathroom_1.jpg"),
      thumbnail: require("@/assets/apartments/f0d1/bathroom_1_tn.jpg"),
      description: "baño completo",
      alt: "baño completo"
    }
  ],
  F0D2: [
    {
      src: require("@/assets/apartments/f0d2/living_1.jpg"),
      thumbnail: require("@/assets/apartments/f0d2/living_1_tn.jpg"),
      description: "Living",
      alt: "salon comedor con television"
    },
    {
      src: require("@/assets/apartments/f0d2/terrace_1.jpg"),
      thumbnail: require("@/assets/apartments/f0d2/terrace_1_tn.jpg"),
      description: "Terrace",
      alt: "terraza con vistas"
    },
    {
      src: require("@/assets/apartments/f0d2/bedroom_1.jpg"),
      thumbnail: require("@/assets/apartments/f0d2/bedroom_1_tn.jpg"),
      description: "First bedroom",
      alt: "primer dormitorio doble"
    },
    {
      src: require("@/assets/apartments/f0d2/kitchen_1.jpg"),
      thumbnail: require("@/assets/apartments/f0d2/kitchen_1_tn.jpg"),
      description: "cocina completamente equipada",
      alt: "cocina completamente equipada"
    },
    {
      src: require("@/assets/apartments/f0d2/kitchen_2.jpg"),
      thumbnail: require("@/assets/apartments/f0d2/kitchen_2_tn.jpg"),
      description: "cocina completamente equipada",
      alt: "cocina completamente equipada"
    },
    {
      src: require("@/assets/apartments/f0d2/bedroom_2.jpg"),
      thumbnail: require("@/assets/apartments/f0d2/bedroom_2_tn.jpg"),
      description: "Second bedroom",
      alt: "segundo dormitorio doble"
    },
    {
      src: require("@/assets/apartments/f0d2/bathroom_1.jpg"),
      thumbnail: require("@/assets/apartments/f0d2/bathroom_1_tn.jpg"),
      description: "baño completo",
      alt: "baño completo"
    }
  ],
  F0D4: [
    {
      src: require("@/assets/apartments/f0d4/living_2.jpg"),
      thumbnail: require("@/assets/apartments/f0d4/living_2_tn.jpg"),
      description: "Living",
      alt: "salon comedor equipado"
    },
    {
      src: require("@/assets/apartments/f0d4/bathroom_1.jpg"),
      thumbnail: require("@/assets/apartments/f0d4/bathroom_1_tn.jpg"),
      description: "baño completo",
      alt: "baño completo"
    },
    {
      src: require("@/assets/apartments/f0d4/kitchen_1.jpg"),
      thumbnail: require("@/assets/apartments/f0d4/kitchen_1_tn.jpg"),
      description: "cocina completamente equipada",
      alt: "cocina completamente equipada"
    },
    {
      src: require("@/assets/apartments/f0d4/bedroom_1.jpg"),
      thumbnail: require("@/assets/apartments/f0d4/bedroom_1_tn.jpg"),
      description: "First bedroom",
      alt: "dormitorio con mesitas de noche y armario"
    },
    {
      src: require("@/assets/apartments/f0d4/bedroom_2.jpg"),
      thumbnail: require("@/assets/apartments/f0d4/bedroom_2_tn.jpg"),
      description: "Second bedroom",
      alt: "dormitorio con mesitas de noche y armario"
    },
    {
      src: require("@/assets/apartments/f0d4/living_1.jpg"),
      thumbnail: require("@/assets/apartments/f0d4/living_1_tn.jpg"),
      description: "Living",
      alt: "Salon con comedor y zona de descanso"
    }
  ],
  F0D6: [
    {
      src: require("@/assets/apartments/f0d6/kitchen_1.jpg"),
      thumbnail: require("@/assets/apartments/f0d6/kitchen_1_tn.jpg"),
      description: "cocina completamente equipada",
      alt: "cocina completamente equipada"
    },
    {
      src: require("@/assets/apartments/f0d6/bedroom_3.jpg"),
      thumbnail: require("@/assets/apartments/f0d6/bedroom_3_tn.jpg"),
      description: "Third bedroom",
      alt: "dormitorio con mesitas de noche y armario"
    },
    {
      src: require("@/assets/apartments/f0d6/living_2.jpg"),
      thumbnail: require("@/assets/apartments/f0d6/living_2_tn.jpg"),
      description: "Living",
      alt: "Salon con comedor y zona de descanso"
    },
    {
      src: require("@/assets/apartments/f0d6/bedroom_2.jpg"),
      thumbnail: require("@/assets/apartments/f0d6/bedroom_2_tn.jpg"),
      description: "Second bedroom",
      alt: "dormitorio con mesitas de noche y armario"
    },
    {
      src: require("@/assets/apartments/f0d6/bedroom_1.jpg"),
      thumbnail: require("@/assets/apartments/f0d6/bedroom_1_tn.jpg"),
      description: "First bedroom",
      alt: "dormitorio con mesitas de noche y armario"
    },
    {
      src: require("@/assets/apartments/f0d6/living_1.jpg"),
      thumbnail: require("@/assets/apartments/f0d6/living_1_tn.jpg"),
      description: "Living",
      alt: "Salon con comedor y zona de descanso"
    },
    {
      src: require("@/assets/apartments/f0d6/bathroom_1.jpg"),
      thumbnail: require("@/assets/apartments/f0d6/bathroom_1_tn.jpg"),
      description: "baño completo",
      alt: "baño completo"
    }
  ],
  F1D1: [
    {
      src: require("@/assets/apartments/f1d1/balcony_1.jpg"),
      thumbnail: require("@/assets/apartments/f1d1/balcony_1_tn.jpg"),
      description: "Balcony",
      alt: "Balcon con vistas al Delta del Ebro"
    },
    {
      src: require("@/assets/apartments/f1d1/bedroom_3.jpg"),
      thumbnail: require("@/assets/apartments/f1d1/bedroom_3_tn.jpg"),
      description: "Third bedroom",
      alt: "dormitorio con mesitas de noche y armario"
    },
    {
      src: require("@/assets/apartments/f1d1/bedroom_2.jpg"),
      thumbnail: require("@/assets/apartments/f1d1/bedroom_2_tn.jpg"),
      description: "Second bedroom",
      alt: "dormitorio con mesitas de noche y armario"
    },
    {
      src: require("@/assets/apartments/f1d1/bedroom_1.jpg"),
      thumbnail: require("@/assets/apartments/f1d1/bedroom_1_tn.jpg"),
      description: "First bedroom",
      alt: "dormitorio con mesitas de noche y armario"
    },
    {
      src: require("@/assets/apartments/f1d1/kitchen_1.jpg"),
      thumbnail: require("@/assets/apartments/f1d1/kitchen_1_tn.jpg"),
      description: "cocina completamente equipada",
      alt: "cocina completamente equipada"
    },
    {
      src: require("@/assets/apartments/f1d1/bathroom_1.jpg"),
      thumbnail: require("@/assets/apartments/f1d1/bathroom_1_tn.jpg"),
      description: "baño completo",
      alt: "baño completo"
    },
    {
      src: require("@/assets/apartments/f1d1/living_1.jpg"),
      thumbnail: require("@/assets/apartments/f1d1/living_1_tn.jpg"),
      description: "Living",
      alt: "Salon con comedor y zona de descanso"
    }
  ],
  F1D2: [
    {
      src: require("@/assets/apartments/f1d2/balcony_1.jpg"),
      thumbnail: require("@/assets/apartments/f1d2/balcony_1_tn.jpg"),
      description: "Balcony",
      alt: "Balcon con vistas al Delta del Ebro"
    },
    {
      src: require("@/assets/apartments/f1d2/bedroom_2.jpg"),
      thumbnail: require("@/assets/apartments/f1d2/bedroom_2_tn.jpg"),
      description: "Second bedroom",
      alt: "dormitorio con mesitas de noche y armario"
    },
    {
      src: require("@/assets/apartments/f1d2/bathroom_1.jpg"),
      thumbnail: require("@/assets/apartments/f1d2/bathroom_1_tn.jpg"),
      description: "baño completo",
      alt: "baño completo"
    },
    {
      src: require("@/assets/apartments/f1d2/kitchen_1.jpg"),
      thumbnail: require("@/assets/apartments/f1d2/kitchen_1_tn.jpg"),
      description: "cocina completamente equipada",
      alt: "cocina completamente equipada"
    },
    {
      src: require("@/assets/apartments/f1d2/living_1.jpg"),
      thumbnail: require("@/assets/apartments/f1d2/living_1_tn.jpg"),
      description: "Living",
      alt: "Salon con comedor y zona de descanso"
    },
    {
      src: require("@/assets/apartments/f1d2/bedroom_1.jpg"),
      thumbnail: require("@/assets/apartments/f1d2/bedroom_1_tn.jpg"),
      description: "First bedroom",
      alt: "dormitorio con mesitas de noche y armario"
    }
  ],
  F4D1: [
    {
      src: require("@/assets/apartments/f4d1/solarium_2.jpg"),
      thumbnail: require("@/assets/apartments/f4d1/solarium_2_tn.jpg"),
      description: "Solarium",
      alt: "Solarium con piscina privada"
    },
    {
      src: require("@/assets/apartments/f4d1/bedroom_3.jpg"),
      thumbnail: require("@/assets/apartments/f4d1/bedroom_3_tn.jpg"),
      description: "Third bedroom",
      alt: "dormitorio con mesitas de noche y armario"
    },
    {
      src: require("@/assets/apartments/f4d1/bedroom_2.jpg"),
      thumbnail: require("@/assets/apartments/f4d1/bedroom_2_tn.jpg"),
      description: "Second bedroom",
      alt: "dormitorio con mesitas de noche y armario"
    },
    {
      src: require("@/assets/apartments/f4d1/bedroom_1.jpg"),
      thumbnail: require("@/assets/apartments/f4d1/bedroom_1_tn.jpg"),
      description: "First bedroom",
      alt: "dormitorio con mesitas de noche y armario"
    },
    {
      src: require("@/assets/apartments/f4d1/solarium_1.jpg"),
      thumbnail: require("@/assets/apartments/f4d1/solarium_1_tn.jpg"),
      description: "Solarium",
      alt: "Solarium con piscina privada"
    },
    {
      src: require("@/assets/apartments/f4d1/living_1.jpg"),
      thumbnail: require("@/assets/apartments/f4d1/living_1_tn.jpg"),
      description: "Living",
      alt: "Salon con comedor y zona de descanso"
    },
    {
      src: require("@/assets/apartments/f4d1/bathroom_1.jpg"),
      thumbnail: require("@/assets/apartments/f4d1/bathroom_1_tn.jpg"),
      description: "baño completo",
      alt: "baño completo"
    }
  ],

  F4D2: [
    {
      src: require("@/assets/apartments/f4d2/bedroom_2.jpg"),
      thumbnail: require("@/assets/apartments/f4d2/bedroom_2_tn.jpg"),
      description: "Second bedroom",
      alt: "dormitorio con mesitas de noche y armario"
    },
    {
      src: require("@/assets/apartments/f4d2/bedroom_1.jpg"),
      thumbnail: require("@/assets/apartments/f4d2/bedroom_1_tn.jpg"),
      description: "First bedroom",
      alt: "dormitorio con mesitas de noche y armario"
    },
    {
      src: require("@/assets/apartments/f4d2/living_1.jpg"),
      thumbnail: require("@/assets/apartments/f4d2/living_1_tn.jpg"),
      description: "Living",
      alt: "Salon con comedor y zona de descanso"
    },
    {
      src: require("@/assets/apartments/f4d2/solarium_1.jpg"),
      thumbnail: require("@/assets/apartments/f4d2/solarium_1_tn.jpg"),
      description: "Solarium",
      alt: "Solarium con piscina privada"
    },
    {
      src: require("@/assets/apartments/f4d2/solarium_2.jpg"),
      thumbnail: require("@/assets/apartments/f4d2/solarium_2_tn.jpg"),
      description: "Solarium",
      alt: "Solarium con piscina privada"
    },
    {
      src: require("@/assets/apartments/f4d2/bathroom_1.jpg"),
      thumbnail: require("@/assets/apartments/f4d2/bathroom_1_tn.jpg"),
      description: "baño completo",
      alt: "baño completo"
    }
  ]
};

export default types;
