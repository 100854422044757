<template>
  <b-col class="mb-3">
    <b-row>
      <b-col cols="auto" class="my-auto">
        <b-img :src="image" class="service-icon" alt="servicio incluido" />
      </b-col>
      <b-col class="my-auto">
        {{ text }}
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
export default {
  name: "FacilityService",

  props: {
    text: String,
    image: String
  }
};
</script>

<style scoped>
.service-icon {
  height: 4em;
}
</style>
