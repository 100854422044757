<template>
  <b-container class="mb-3" id="features-container">
    <b-row>
      <b-col cols="12" xl="5">
        <b-row>
          <b-col cols="12" md="6" xl="6" class="mx-auto">
            <ApartmentFeature
              :featureName="$t('apartments.item-bedrooms')"
              :featureValue="
                bedrooms +
                  ' ' +
                  $tc('apartments.item-bedrooms-double', bedrooms)
              "
            />
          </b-col>
          <b-col cols="12" md="6" xl="6" class="mx-auto">
            <ApartmentFeature
              :featureName="$t('apartments.item-beds')"
              :featureValue="beds + ' ' + $t('apartments.item-beds-single')"
            />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" xl="7" class="my-auto">
        <b-row>
          <b-col cols="12" md="4" xl="4">
            <ApartmentFeature
              :featureName="$t('apartments.item-low-season')"
              :featureValue="
                lowSeasonFare + ' ' + $t('apartments.item-price-label')
              "
            />
          </b-col>
          <b-col cols="12" md="4" xl="4">
            <ApartmentFeature
              :featureName="$t('apartments.item-mid-season')"
              :featureValue="
                midSeasonFare + ' ' + $t('apartments.item-price-label')
              "
            />
          </b-col>
          <b-col cols="12" md="4" xl="4">
            <ApartmentFeature
              :featureName="$t('apartments.item-high-season')"
              :featureValue="
                highSeasonFare + ' ' + $t('apartments.item-price-label')
              "
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ApartmentFeature from "@/components/ApartmentFeature.vue";

export default {
  name: "ApartmentFeatures",

  components: {
    ApartmentFeature
  },

  props: {
    capacity: Number,
    beds: String,
    bedrooms: String,
    balconies: Number,
    lowSeasonFare: Number,
    midSeasonFare: Number,
    highSeasonFare: Number
  }
};
</script>
