<template>
  <b-container class="mb-md-2 apartment-feature">
    <b-row>
      <b-col cols="6" md="12" class="p-0 text-md-center mt-1 mt-xl-1">
        <h4>{{ featureName }}</h4>
      </b-col>
      <b-col cols="6" md="12" class="text-center mt-1 mt-xl-2">
        {{ featureValue }}
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "ApartmentFeature",

  props: ["featureName", "featureValue"]
};
</script>

<style scoped></style>
