<template>
  <b-container fluid class="main-container">
    <b-row>
      <b-col md="8" offset-md="2" class="main-col">
        <b-row class="description-margin">
          <b-col>
            <h1>{{ $t("facilities.title") }}</h1>
            <p class="important-text">{{ $t("facilities.description") }}</p>
          </b-col>
        </b-row>
        <FacilitiesItem
          :title="$t('facilities.living-title')"
          :reverse="false"
          :gallery="gallery_living"
        >
          {{ $t("facilities.living-description") }}
        </FacilitiesItem>
        <FacilitiesItem
          :title="$t('facilities.kitchen-title')"
          :reverse="true"
          :gallery="gallery_kitchen"
        >
          {{ $t("facilities.kitchen-description") }}
        </FacilitiesItem>
        <FacilitiesItem
          :title="$t('facilities.bedrooms-title')"
          :reverse="false"
          :gallery="gallery_bedrooms"
        >
          {{ $t("facilities.bedrooms-description") }}
        </FacilitiesItem>
        <FacilitiesItem
          :title="$t('facilities.bathroom-title')"
          :reverse="true"
          :gallery="gallery_bathroom"
        >
          {{ $t("facilities.bathroom-description") }}
        </FacilitiesItem>
        <FacilitiesItem
          :title="$t('facilities.adapted-title')"
          :reverse="false"
          :gallery="gallery_adapted"
        >
          {{ $t("facilities.adapted-description") }}
          <router-link to="/instalaciones/movilidad-reducida">
            {{ $t("facilities.adapted-know-more") }}
          </router-link>
        </FacilitiesItem>
        <FacilitiesItem
          :title="$t('facilities.building-title')"
          :reverse="true"
          :gallery="gallery_building"
        >
          {{ $t("facilities.building-description") }}
        </FacilitiesItem>
        <h2 class="mb-4">{{ $t("facilities.services") }}</h2>
        <b-row cols="1" cols-md="2">
          <FacilitiesService
            :text="$t('facilities.service-cradle')"
            :image="images.cradle_icon"
          />
          <FacilitiesService
            :text="$t('facilities.service-towels')"
            :image="images.towels_icon"
          />
          <FacilitiesService
            :text="$t('facilities.service-pets')"
            :image="images.pets_icon"
          />
          <FacilitiesService
            :text="
              $t('facilities.service-bed-1') +
                extraBedPrice +
                $t('facilities.service-bed-2')
            "
            :image="images.bed_icon"
          />
          <FacilitiesService
            :text="$t('facilities.service-wifi')"
            :image="images.wifi_icon"
          />
          <FacilitiesService
            :text="$t('facilities.service-parking')"
            :image="images.parking_icon"
            class="mb-5"
          />
        </b-row>
        <b-row class="text-center">
          <b-col>
            <b-button
              to="/apartamentos"
              class="carolinas-button navigate-button"
              v-on:click="ApartmentsClick"
              >{{ $t("facilities.apartments-button") }}</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FacilitiesItem from "@/components/FacilitiesItem.vue";
import FacilitiesService from "@/components/FacilitiesService.vue";
import apartmentFares from "@/data/apartmentFares.json";

export default {
  name: "Facilities",

  components: {
    FacilitiesItem,
    FacilitiesService
  },

  metaInfo: {
    title:
      "Las Carolinas | Conoce tu apartamento vacacional en el Delta del Ebro",

    link: [
      {
        rel: "alternate",
        hreflang: "es",
        href: "https://www.lascarolinas.net/es/instalaciones"
      },
      {
        rel: "alternate",
        hreflang: "ca",
        href: "https://www.lascarolinas.net/ca/instalaciones"
      },
      {
        rel: "alternate",
        hreflang: "en",
        href: "https://www.lascarolinas.net/en/instalaciones"
      },
      {
        rel: "alternate",
        hreflang: "fr",
        href: "https://www.lascarolinas.net/fr/instalaciones"
      }
    ],

    meta: [
      {
        name: "description",
        content:
          "Con piscina privada, para seis personas, con balcón o adaptado a movilidad reducida. ¡Conoce todos los detalles tu apartamento y ven a conocer el Delta del Ebro!"
      }
    ]
  },

  data: function() {
    return {
      images: {
        cradle_icon: require("@/assets/facilities/cradle_icon.png"),
        towels_icon: require("@/assets/facilities/towels_icon.png"),
        pets_icon: require("@/assets/facilities/pets_icon.png"),
        bed_icon: require("@/assets/facilities/bed_icon.png"),
        wifi_icon: require("@/assets/facilities/wifi_icon.png"),
        parking_icon: require("@/assets/facilities/parking_icon.png")
      }
    };
  },

  methods: {
    ApartmentsClick: function() {
      this.$gtag.event("ApartmentsClick", {
        event_category: "HappyPath",
        event_label: "FacilitiesPage"
      });
    }
  },

  computed: {
    extraBedPrice: function() {
      return apartmentFares.types["ALL"].extraBed;
    },

    gallery_living: function() {
      return [
        {
          src: require("@/assets/facilities/living_3.jpg"),
          thumbnail: require("@/assets/facilities/living_3_tn.jpg"),
          description: this.$i18n.t("facilities.living-image-description-1"),
          alt: "salon comedor de los apartamentos"
        },
        {
          src: require("@/assets/facilities/living_2.jpg"),
          thumbnail: require("@/assets/facilities/living_2_tn.jpg"),
          description: this.$i18n.t("facilities.living-image-description-2"),
          alt: "terraza con vistas"
        }
      ];
    },

    gallery_kitchen: function() {
      return [
        {
          src: require("@/assets/facilities/kitchen_1.jpg"),
          thumbnail: require("@/assets/facilities/kitchen_1_tn.jpg"),
          description: this.$i18n.t("facilities.kitchen-image-description-1"),
          alt: "cocina completamente equipada"
        },
        {
          src: require("@/assets/facilities/kitchen_3.jpg"),
          thumbnail: require("@/assets/facilities/kitchen_3_tn.jpg"),
          description: this.$i18n.t("facilities.kitchen-image-description-2"),
          alt: "cocina completamente equipada"
        }
      ];
    },

    gallery_bedrooms: function() {
      return [
        {
          src: require("@/assets/facilities/bedrooms_1.jpg"),
          thumbnail: require("@/assets/facilities/bedrooms_1_tn.jpg"),
          description: this.$i18n.t("facilities.bedrooms-image-description-1"),
          alt: "dormitorio doble confortable"
        },
        {
          src: require("@/assets/facilities/bedrooms_3.jpg"),
          thumbnail: require("@/assets/facilities/bedrooms_3_tn.jpg"),
          description: this.$i18n.t("facilities.bedrooms-image-description-2"),
          alt: "dormitorio simple confortable"
        }
      ];
    },

    gallery_bathroom: function() {
      return [
        {
          src: require("@/assets/facilities/bathroom_1.jpg"),
          thumbnail: require("@/assets/facilities/bathroom_1_tn.jpg"),
          description: this.$i18n.t("facilities.bathroom-image-description-1"),
          alt: "baño completo"
        },
        {
          src: require("@/assets/facilities/bathroom_3.jpg"),
          thumbnail: require("@/assets/facilities/bathroom_3_tn.jpg"),
          description: this.$i18n.t("facilities.bathroom-image-description-2"),
          alt: "baño completo"
        }
      ];
    },

    gallery_adapted: function() {
      return [
        {
          src: require("@/assets/facilities/adapted_3.jpg"),
          thumbnail: require("@/assets/facilities/adapted_3_tn.jpg"),
          description: this.$i18n.t("facilities.adapted-image-description-1"),
          alt: "piscina adaptada a movilidad reducida"
        },
        {
          src: require("@/assets/facilities/adapted_2.jpg"),
          thumbnail: require("@/assets/facilities/adapted_2_tn.jpg"),
          description: this.$i18n.t("facilities.adapted-image-description-2"),
          alt: "piscina adaptada a movilidad reducida"
        }
      ];
    },

    gallery_building: function() {
      return [
        {
          src: require("@/assets/facilities/building_1.jpg"),
          thumbnail: require("@/assets/facilities/building_1_tn.jpg"),
          description: this.$i18n.t("facilities.building-image-description-1"),
          alt: "terraza comunitaria"
        },
        {
          src: require("@/assets/facilities/building_2.jpg"),
          thumbnail: require("@/assets/facilities/building_2_tn.jpg"),
          description: this.$i18n.t("facilities.building-image-description-2"),
          alt: "piscina comunitaria"
        }
      ];
    }
  }
};
</script>

<style scoped></style>
