<template>
  <b-navbar toggleable="md" type="dark" id="navbar">
    <b-navbar-brand :to="{ path: '/' + this.$i18n.locale + '/' }" id="logo">
      <b-img :src="images.logo" fluid alt="Las Carolinas logo" />
    </b-navbar-brand>
    <b-navbar-toggle target="collapse"></b-navbar-toggle>

    <b-collapse is-nav id="collapse">
      <b-navbar-nav>
        <b-nav-item
          :to="{ path: '/' + this.$i18n.locale }"
          v-on:click="startClick"
          >{{ $t("header.start") }}</b-nav-item
        >
        <b-nav-item to="/delta-ebro" v-on:click="locationClick">{{
          $t("header.location")
        }}</b-nav-item>
        <b-nav-item to="/instalaciones" v-on:click="facilitiesClick">{{
          $t("header.facilities")
        }}</b-nav-item>
        <b-nav-item to="/apartamentos" v-on:click="apartmentsClick">{{
          $t("header.apartments")
        }}</b-nav-item>
        <b-nav-item to="/booking" v-on:click="bookingClick">{{
          $t("header.booking")
        }}</b-nav-item>
        <b-nav-item-dropdown :text="$t('header.languaje')" toggle-class="">
          <b-dropdown-item v-on:click="changeToSpanish"
            >ESPAÑOL</b-dropdown-item
          >
          <b-dropdown-item v-on:click="changeToCatalan">CATALÀ</b-dropdown-item>
          <b-dropdown-item v-on:click="changeToEnglish"
            >ENGLISH</b-dropdown-item
          >
          <b-dropdown-item v-on:click="changeToFrench"
            >FRANÇAIS</b-dropdown-item
          >
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "PageHeader",

  data: function() {
    return {
      images: {
        logo: require("../assets/pageheader_logo_text_alpha_white_low_res.png")
      }
    };
  },

  methods: {
    changeLanguage: function(newLang) {
      if (this.$route.params.lang) {
        this.$router.replace({ params: { lang: newLang } });
      } else {
        this.$router.push({
          path: "/" + newLang + this.$route.path,
          params: { lang: newLang }
        });
      }
    },
    changeToSpanish() {
      this.$router.replace({ params: { lang: "es" } }).catch(e => {});
    },
    changeToCatalan() {
      this.changeLanguage("ca");
    },
    changeToEnglish() {
      this.changeLanguage("en");
    },
    changeToFrench() {
      this.changeLanguage("fr");
    },
    startClick() {
      this.$gtag.event("LandingClick", {
        event_category: "Navigate",
        event_label: "HeaderLink"
      });
    },
    locationClick() {
      this.$gtag.event("LocationClick", {
        event_category: "Navigate",
        event_label: "HeaderLink"
      });
    },
    facilitiesClick() {
      this.$gtag.event("FacilitiesClick", {
        event_category: "Navigate",
        event_label: "HeaderLink"
      });
    },
    apartmentsClick() {
      this.$gtag.event("ApartmentsClick", {
        event_category: "Navigate",
        event_label: "HeaderLink"
      });
    },
    bookingClick() {
      this.$gtag.event("BookingClick", {
        event_category: "Navigate",
        event_label: "HeaderLink"
      });
    }
  }
};
</script>

<style scoped>
#navbar {
  background-color: #68a26e;
  color: white;
  border-bottom: 1px solid #629868;
  font-weight: normal;
}

#logo {
  width: 5em;
}
</style>
