<template>
  <b-container fluid class="main-container">
    <b-row>
      <b-col md="8" offset-md="2" class="main-col">
        <b-row class="mb-3">
          <b-col>
            <h1>{{ $t("coronavirus.title") }}</h1>
            <p class="important-text">
              {{ $t("coronavirus.description") }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="10" offset-md="1">
            <FacilitiesService
              :text="$t('coronavirus.screen')"
              :image="images.screen_icon"
            />
            <FacilitiesService
              :text="$t('coronavirus.hydrogel')"
              :image="images.hydrogel_icon"
            />
            <FacilitiesService
              :text="$t('coronavirus.laundry')"
              :image="images.laundry_icon"
            />
            <FacilitiesService
              :text="$t('coronavirus.soap')"
              :image="images.soap_icon"
            />
            <FacilitiesService
              :text="$t('coronavirus.entrance')"
              :image="images.entrance_icon"
            />
            <FacilitiesService
              :text="$t('coronavirus.pool')"
              :image="images.pool_icon"
            />
            <FacilitiesService
              :text="$t('coronavirus.distance')"
              :image="images.distance_icon"
              class="mb-4"
            />
            <b-button
              class="carolinas-button float-right navigate-button"
              v-on:click="goBack"
            >
              {{ $t("coronavirus.back-button") }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FacilitiesService from "@/components/FacilitiesService.vue";

export default {
  name: "Coronavirus",

  components: {
    FacilitiesService
  },

  metaInfo: {
    title: "Las Carolinas | Visita el Delta del Ebro protegido ante COVID-19",

    link: [
      {
        rel: "alternate",
        hreflang: "es",
        href: "https://www.lascarolinas.net/es/coronavirus"
      },
      {
        rel: "alternate",
        hreflang: "ca",
        href: "https://www.lascarolinas.net/ca/coronavirus"
      },
      {
        rel: "alternate",
        hreflang: "en",
        href: "https://www.lascarolinas.net/en/coronavirus"
      },
      {
        rel: "alternate",
        hreflang: "fr",
        href: "https://www.lascarolinas.net/fr/coronavirus"
      }
    ],

    meta: [
      {
        name: "description",
        content:
          "Hemos implementado varias medidas contra el nuevo coronavirus (COVID-19) para que visites el Delta del Ebro de forma segura. Entra y conócelas"
      }
    ]
  },

  data: function() {
    return {
      images: {
        screen_icon: require("@/assets/coronavirus/screen_icon.png"),
        hydrogel_icon: require("@/assets/coronavirus/hydrogel_icon.png"),
        laundry_icon: require("@/assets/coronavirus/laundry_icon_2.png"),
        soap_icon: require("@/assets/coronavirus/soap_icon.png"),
        entrance_icon: require("@/assets/coronavirus/entrance_icon.png"),
        pool_icon: require("@/assets/coronavirus/pool_icon.png"),
        distance_icon: require("@/assets/coronavirus/distance_icon.png")
      }
    };
  },

  methods: {
    goBack: function() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped></style>
