<template>
  <b-container fluid class="main-container">
    <b-row>
      <b-col md="8" offset-md="2" class="main-col">
        <b-row class="mb-3">
          <b-col>
            <h1>{{ $t("disabled.title") }}</h1>
            <p class="important-text">
              {{ $t("disabled.description") }}
            </p>
          </b-col>
        </b-row>
        <h2>{{ $t("disabled.kitchen-title") }}</h2>
        <ul class="mb-4">
          <li>
            {{ $t("disabled.kitchen-feature-1") }}
          </li>
          <li>{{ $t("disabled.kitchen-feature-2") }}</li>
          <li>{{ $t("disabled.kitchen-feature-3") }}</li>
        </ul>
        <h2>{{ $t("disabled.living-title") }}</h2>
        <ul class="mb-4">
          <li>{{ $t("disabled.living-feature-1") }}</li>
          <li>{{ $t("disabled.living-feature-2") }}</li>
          <li>{{ $t("disabled.living-feature-3") }}</li>
        </ul>
        <h2>{{ $t("disabled.bedroom-title") }}</h2>
        <ul class="mb-4">
          <li>
            {{ $t("disabled.bedroom-feature-1") }}
          </li>
          <li>{{ $t("disabled.bedroom-feature-2") }}</li>
        </ul>
        <h2>{{ $t("disabled.bathroom-title") }}</h2>
        <ul class="mb-4">
          <li>
            {{ $t("disabled.bathroom-feature-1") }}
          </li>
          <li>
            {{ $t("disabled.bathroom-feature-2") }}
          </li>
          <li>{{ $t("disabled.bathroom-feature-3") }}</li>
          <li>
            {{ $t("disabled.bathroom-feature-4") }}
          </li>
          <li>{{ $t("disabled.bathroom-feature-5") }}</li>
        </ul>
        <h2>{{ $t("disabled.building-title") }}</h2>
        <ul class="mb-5">
          <li>
            {{ $t("disabled.building-feature-1") }}
          </li>
          <li>
            {{ $t("disabled.building-feature-2") }}
          </li>
          <li>
            {{ $t("disabled.building-feature-3") }}
          </li>
        </ul>
        <b-row class="mb-5">
          <b-col class="text-center">
            <silent-box :gallery="gallery"></silent-box>
          </b-col>
        </b-row>
        <p class="mb-5">{{ $t("disabled.greetings") }}</p>
        <b-row class="mb-3">
          <b-col class="text-center">
            <b-link
              href="https://www.equalitasvitae.com/es/guia/turismo_adaptado.php?local=las_carolinas"
            >
              <b-img
                :src="images.equalitas"
                alt="certificacion mobilidad reducida"
                class="logo mr-sm-5 mr-2"
              >
              </b-img>
            </b-link>
            <b-link
              href="http://turismoparatodos.catalunya.com/es/allotjament.php?p=990&pag=4&pagdev=ondormirresults&acces=&marca=&destinacio=&alojamiento=&orderby=nombre"
            >
              <b-img
                :src="images.tourism"
                alt="certificacion turismo accesible"
                class="logo"
              >
              </b-img>
            </b-link>
          </b-col>
        </b-row>
        <b-button
          class="carolinas-button float-right navigate-button"
          v-on:click="goBack"
        >
          {{ $t("disabled.back-button") }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Disabled",

  metaInfo: {
    title: "Las Carolinas | Apartamentos adaptados en el Delta del Ebro",

    link: [
      {
        rel: "alternate",
        hreflang: "es",
        href: "https://www.lascarolinas.net/es/movilidad-reducida"
      },
      {
        rel: "alternate",
        hreflang: "ca",
        href: "https://www.lascarolinas.net/ca/movilidad-reducida"
      },
      {
        rel: "alternate",
        hreflang: "en",
        href: "https://www.lascarolinas.net/en/movilidad-reducida"
      },
      {
        rel: "alternate",
        hreflang: "fr",
        href: "https://www.lascarolinas.net/fr/movilidad-reducida"
      }
    ],

    meta: [
      {
        name: "description",
        content:
          "Disponemos de apartamenos adaptados para la movilidad reducida (silla de ruedas) para que todo le mundo pueda visitar el Delta del Ebro"
      }
    ]
  },

  data: function() {
    return {
      images: {
        equalitas: require("@/assets/facilities/disabled/equalitas.png"),
        tourism: require("@/assets/facilities/disabled/tourism_logo.png")
      }
    };
  },

  computed: {
    gallery: function() {
      return [
        {
          src: require("@/assets/facilities/disabled/kitchen_1.jpg"),
          thumbnail: require("@/assets/facilities/disabled/kitchen_1_tn.jpg"),
          description: this.$i18n.t("facilities.kitchen-image-description-2"),
          alt: "cocina accesible con silla de ruedas"
        },
        {
          src: require("@/assets/facilities/disabled/pool_1.jpg"),
          thumbnail: require("@/assets/facilities/disabled/pool_1_tn.jpg"),
          description: this.$i18n.t("facilities.building-image-description-2"),
          alt: "piscina con silla hidraulica"
        },
        {
          src: require("@/assets/facilities/disabled/bathroom_1.jpg"),
          thumbnail: require("@/assets/facilities/disabled/bathroom_1_tn.jpg"),
          description: this.$i18n.t("facilities.bathroom-image-description-1"),
          alt: "inodoro con asidero"
        },
        {
          src: require("@/assets/facilities/disabled/bathroom_2.jpg"),
          thumbnail: require("@/assets/facilities/disabled/bathroom_2_tn.jpg"),
          description: this.$i18n.t("facilities.bathroom-image-description-2"),
          alt: "inodoro con asidero"
        },
        {
          src: require("@/assets/facilities/disabled/bathroom_3.jpg"),
          thumbnail: require("@/assets/facilities/disabled/bathroom_3_tn.jpg"),
          description: this.$i18n.t("facilities.bathroom-image-description-2"),
          alt: "ducha a cota cero"
        }
      ];
    }
  },

  methods: {
    goBack: function() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.logo {
  height: 4em;
  margin-bottom: 2rem;
}

::v-deep .silentbox-item {
  width: 50%;
  max-width: 180px;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
}

::v-deep .silentbox-item img {
  width: 100%;
  border-radius: 0.5rem;
}

::v-deep #silentbox-overlay__embed {
  width: 85%;
}

::v-deep #silentbox-overlay__arrow-buttons .arrow {
  height: 2.5rem;
  width: 2.5rem;
}

::v-deep #silentbox-overlay__arrow-buttons .arrow-next {
  top: 80%;
}

::v-deep #silentbox-overlay__arrow-buttons .arrow-previous {
  top: 80%;
}
</style>
