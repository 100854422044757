<template>
  <div id="coronavirus-landing-main">
    <div id="coronavirus-main-row">
      <Landing />
    </div>
    <router-link to="/coronavirus" v-on:click.native="coronavirusClick">
      <div id="coronavirus-main-row-2" class="text-center my-auto">
        <b-img
          :src="images.coronavirus_icon"
          class="mr-3 my-auto"
          id="icon"
          alt="Coronavirus"
        />
        <span class="my-auto">
          {{ $t("coronavirus.warning") }}
        </span>
      </div>
    </router-link>
  </div>
</template>

<script>
import Landing from "@/views/Landing.vue";

export default {
  name: "LandingCoronavirus",

  components: {
    Landing
  },

  data: function() {
    return {
      images: {
        coronavirus_icon: require("@/assets/coronavirus/coronavirus_icon.png")
      }
    };
  },

  methods: {
    coronavirusClick: function() {
      this.$gtag.event("CoronaClick", {
        event_category: "Navigate",
        event_label: "LandingPage"
      });
    }
  }
};
</script>

<style scoped>
#coronavirus-landing-main {
  display: flex;
  flex: auto;
  flex-direction: column;
}

#coronavirus-main-row {
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
}

#coronavirus-main-row-2 {
  display: flex;
  flex-basis: auto;
  flex-grow: 0;
  justify-content: center;
  background-color: #698ed3;
  color: black;
  border-top: #707070 1px solid;
  border-bottom: #707070 1px solid;
  padding: 1rem;
}

#coronavirus-main-col {
  flex-basis: auto;
}

#coronavirus-warning {
  background-color: #698ed3;
  color: black;
  border-top: #707070 1px solid;
  border-bottom: #707070 1px solid;
}

#icon {
  height: 2.5em;
}

a {
  text-decoration: none !important;
}
</style>
