<template>
  <b-container fluid class="main-container">
    <b-row>
      <b-col md="8" offset-md="2" class="main-col">
        <b-row class="description-margin">
          <b-col>
            <h1>{{ $t("location.title") }}</h1>
            <p class="important-text">{{ $t("location.description") }}</p>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="6" class="mb-2">
            <div id="location-map">
              <b-embed
                type="iframe"
                aspect="4by3"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3028.104534376748!2d0.594124015814248!3d40.62757657934117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a0555150fbfb61%3A0x9b16b36b19f7a670!2sApartaments%20Las%20Carolinas!5e0!3m2!1sen!2ses!4v1589107885282!5m2!1sen!2ses"
                allowfullscreen
              ></b-embed>
            </div>
          </b-col>
          <b-col md="6" class="mb-2">
            <silent-box :gallery="gallery_town"></silent-box>
          </b-col>
        </b-row>
        <p>{{ $t("location.details") }}</p>
        <b-row align-h="center" class="mb-5">
          <b-col cols="3" class="text-right my-auto">
            <b-img :src="images.location" alt="dirección logo" />
          </b-col>
          <b-col cols="9">
            {{ $t("location.details-street") }}
            <br />
            {{ $t("location.details-town") }}
          </b-col>
        </b-row>
        <h3>{{ $t("location.gastronomy") }}</h3>
        <p>{{ $t("location.gastronomy-description") }}</p>
        <b-row align-h="center" class="gallery-row">
          <b-col cols="auto">
            <silent-box
              :gallery="gallery_gastronomy"
              class="two-thumbnails"
            ></silent-box>
          </b-col>
        </b-row>
        <h3>{{ $t("location.beaches") }}</h3>
        <p>{{ $t("location.beaches-description") }}</p>
        <b-row align-h="center" class="gallery-row">
          <b-col cols="auto">
            <silent-box
              :gallery="gallery_beaches"
              class="two-thumbnails"
            ></silent-box>
          </b-col>
        </b-row>
        <h3>{{ $t("location.leisure") }}</h3>
        <p>{{ $t("location.leisure-description") }}</p>
        <b-row align-h="center" class="gallery-row">
          <b-col cols="auto">
            <silent-box
              :gallery="gallery_leisure"
              class="two-thumbnails"
            ></silent-box>
          </b-col>
        </b-row>
        <h3>{{ $t("location.surroundings") }}</h3>
        <p>{{ $t("location.surroundings-description") }}</p>
        <b-row align-h="center" class="gallery-row">
          <b-col cols="auto">
            <silent-box
              :gallery="gallery_park"
              class="two-thumbnails"
            ></silent-box>
          </b-col>
        </b-row>
        <b-row class="text-center">
          <b-col>
            <b-button
              to="/instalaciones"
              class="carolinas-button navigate-button"
              v-on:click="FacilitiesClick"
              >{{ $t("location.facilities-button") }}</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Location",

  metaInfo: {
    title: "Las Carolinas | Descubre el Delta del Ebro",

    link: [
      {
        rel: "alternate",
        hreflang: "es",
        href: "https://www.lascarolinas.net/es/delta-ebro"
      },
      {
        rel: "alternate",
        hreflang: "ca",
        href: "https://www.lascarolinas.net/ca/delta-ebro"
      },
      {
        rel: "alternate",
        hreflang: "en",
        href: "https://www.lascarolinas.net/en/delta-ebro"
      },
      {
        rel: "alternate",
        hreflang: "fr",
        href: "https://www.lascarolinas.net/fr/delta-ebro"
      }
    ],

    meta: [
      {
        name: "description",
        content:
          "En el Delta del Ebro hay más de 300 especies de aves identificadas y largas playas para disfrutar en familia y con amigos. ¡Ven y conoce sus encantos!"
      }
    ]
  },

  data: function() {
    return {
      images: {
        location: require("@/assets/pagefooter_location.png")
      }
    };
  },

  methods: {
    FacilitiesClick: function() {
      this.$gtag.event("FacilitiesClick", {
        event_category: "HappyPath",
        event_label: "LocationPage"
      });
    }
  },

  computed: {
    gallery_town: function() {
      return [
        {
          src: require("@/assets/location/town_1.jpg"),
          thumbnail: require("@/assets/location/town_1_tn.jpg"),
          description: this.$i18n.t("location.town-image-description-1"),
          alt: "Sant Carles de la Ràpita - Aerial"
        },
        {
          src: require("@/assets/location/town_2.jpg"),
          thumbnail: require("@/assets/location/town_2_tn.jpg"),
          description: this.$i18n.t("location.town-image-description-2"),
          alt: "Sant Carles de la Ràpita - Port"
        },
        {
          src: require("@/assets/location/town_3.jpg"),
          thumbnail: require("@/assets/location/town_3_tn.jpg"),
          description: this.$i18n.t("location.town-image-description-3"),
          alt: "Sant Carles de la Ràpita - Town Square"
        },
        {
          src: require("@/assets/location/town_4.jpg"),
          thumbnail: require("@/assets/location/town_4_tn.jpg"),
          description: this.$i18n.t("location.town-image-description-4"),
          alt: "Sant Carles de la Ràpita - Church"
        }
      ];
    },
    gallery_gastronomy: function() {
      return [
        {
          src: require("@/assets/location/gastronomy_3.jpg"),
          thumbnail: require("@/assets/location/gastronomy_3_tn.jpg"),
          description: this.$i18n.t("location.gastronomy-image-description-1"),
          alt: "Paella tipica del Delta del Ebro"
        },
        {
          src: require("@/assets/location/gastronomy_2.jpg"),
          thumbnail: require("@/assets/location/gastronomy_2_tn.jpg"),
          description: this.$i18n.t("location.gastronomy-image-description-2"),
          alt: "Marisco tipico del Delta del Ebro"
        }
      ];
    },
    gallery_beaches: function() {
      return [
        {
          src: require("@/assets/location/beaches_1.jpg"),
          thumbnail: require("@/assets/location/beaches_1_tn.jpg"),
          description: this.$i18n.t("location.beaches-image-description-1"),
          alt: "playa de Sant Carles de la Rapita"
        },
        {
          src: require("@/assets/location/beaches_2.jpg"),
          thumbnail: require("@/assets/location/beaches_2_tn.jpg"),
          description: this.$i18n.t("location.beaches-image-description-2"),
          alt: "playa de Sant Carles de la Rapita"
        }
      ];
    },
    gallery_leisure: function() {
      return [
        {
          src: require("@/assets/location/leisure_1.jpg"),
          thumbnail: require("@/assets/location/leisure_1_tn.jpg"),
          description: this.$i18n.t("location.leisure-image-description-1"),
          alt: "familia en una canoa"
        },
        {
          src: require("@/assets/location/leisure_2.jpg"),
          thumbnail: require("@/assets/location/leisure_2_tn.jpg"),
          description: this.$i18n.t("location.leisure-image-description-2"),
          alt: "Windsurf en la bahia de los Alfacs"
        },
        {
          src: require("@/assets/location/leisure_3.jpg"),
          thumbnail: require("@/assets/location/leisure_3_tn.jpg"),
          description: this.$i18n.t("location.leisure-image-description-3"),
          alt: "Kitesurf en la bahia de los Alfacs"
        }
      ];
    },
    gallery_park: function() {
      return [
        {
          src: require("@/assets/location/park_1.jpg"),
          thumbnail: require("@/assets/location/park_1_tn.jpg"),
          description: this.$i18n.t(
            "location.surroundings-image-description-1"
          ),
          alt: "pajaro en el Parque Natural del Delta del Ebro"
        },
        {
          src: require("@/assets/location/park_2.jpg"),
          thumbnail: require("@/assets/location/park_2_tn.jpg"),
          description: this.$i18n.t(
            "location.surroundings-image-description-2"
          ),
          alt: "barca abandonada en el Parque Natural del Delta del Ebro"
        },
        {
          src: require("@/assets/location/park_3.jpg"),
          thumbnail: require("@/assets/location/park_3_tn.jpg"),
          description: this.$i18n.t(
            "location.surroundings-image-description-3"
          ),
          alt: "residencia tradicional del delta"
        },
        {
          src: require("@/assets/location/park_4.jpg"),
          thumbnail: require("@/assets/location/park_4_tn.jpg"),
          description: this.$i18n.t(
            "location.surroundings-image-description-4"
          ),
          alt: "pajaro entre campos de arroz"
        }
      ];
    }
  }
};
</script>

<style scoped>
#location-map {
  border: 1px solid #c1c1c1;
  box-shadow: 0 18px 16px -15px grey;
}

.gallery-row {
  margin-bottom: 3rem;
}

::v-deep .silentbox-item {
  width: 50%;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
}

::v-deep .silentbox-item img {
  width: 100%;
  border-radius: 0.5rem;
}

::v-deep #silentbox-overlay__embed {
  width: 85%;
}

::v-deep #silentbox-overlay__arrow-buttons .arrow {
  height: 2.5rem;
  width: 2.5rem;
}

::v-deep #silentbox-overlay__arrow-buttons .arrow-next {
  top: 80%;
}

::v-deep #silentbox-overlay__arrow-buttons .arrow-previous {
  top: 80%;
}

::v-deep .two-thumbnails .silentbox-item:nth-of-type(n + 3) {
  /* Hide all images after the 4th one */
  display: none;
}
</style>
