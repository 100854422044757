<template>
  <b-container id="apartment-container">
    <b-row align-h="end">
      <b-col cols="auto">
        <div v-if="solarium" class="special-feature">
          {{ $t("apartments.item-solarium") }}
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h3 class="apartment-title">{{ title }}</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="4" class="my-auto">
        <silent-box :gallery="gallery" class="text-center mb-3"></silent-box>
      </b-col>
      <b-col cols="12" md="8" class="p-0">
        <ApartmentFeatures
          :bedrooms="bedrooms"
          :beds="beds"
          :balconies="balconies"
          :lowSeasonFare="lowSeasonFare"
          :midSeasonFare="midSeasonFare"
          :highSeasonFare="highSeasonFare"
        />
        <div class="d-none d-xl-block pr-3">
          <p :class="{ collapsed: collapsedDescription }">{{ description }}</p>
          <span
            @click="readMore"
            v-show="collapsedDescription"
            class="collapse-text"
            >{{ $t("apartments.read-more") }}</span
          >
          <span
            @click="readMore"
            v-show="!collapsedDescription"
            class="collapse-text"
            >{{ $t("apartments.read-less") }}</span
          >
          <b-button
            v-on:click="goBooking"
            class="carolinas-button float-right navigate-button mt-3"
          >
            {{ $t("apartments.book-button") }}
          </b-button>
        </div>
      </b-col>
      <b-col cols="12" md="12" class="d-xl-none">
        <b-row>
          <b-col>
            <p :class="{ collapsed: collapsedDescription }">
              {{ description }}
            </p>
            <span
              @click="readMore"
              v-show="collapsedDescription"
              class="collapse-text"
              >{{ $t("apartments.read-more") }}</span
            >
            <span
              @click="readMore"
              v-show="!collapsedDescription"
              class="collapse-text"
              >{{ $t("apartments.read-less") }}</span
            >
          </b-col>
        </b-row>
        <b-button
          v-on:click="goBooking"
          class="carolinas-button float-right navigate-button"
        >
          {{ $t("apartments.book-button") }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ApartmentFeatures from "@/components/ApartmentFeatures.vue";

export default {
  name: "ApartmentsItem",

  components: {
    ApartmentFeatures
  },

  props: {
    title: String,
    gallery: Array,
    capacity: Number,
    beds: String,
    bedrooms: String,
    balconies: Number,
    adapted: Boolean,
    solarium: Boolean,
    description: String,
    lowSeasonFare: Number,
    midSeasonFare: Number,
    highSeasonFare: Number
  },

  data: function() {
    return {
      collapsedDescription: true
    };
  },

  methods: {
    readMore: function() {
      this.collapsedDescription = !this.collapsedDescription;
    },

    goBooking: function() {
      this.$router
        .push({
          path: "/booking",
          query: {
            description:
              this.$i18n.t("apartments.book-button-message-prefix") + this.title
          }
        })
        .catch(e => {});

      this.$gtag.event("BookingClick", {
        event_category: "HappyPath",
        event_label: "ApartmentsPage"
      });
    }
  }
};
</script>

<style scoped>
#apartment-container {
  background-color: #f3f8f2;
  border: 1px solid #707070;
  padding: 1rem;
  box-shadow: 0 18px 16px -15px grey;
  border-radius: 0.3rem;
}

.special-feature {
  background-color: #707070;
  color: white;
  font-weight: normal;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.1rem;
  border: 1px solid black;
  border-radius: 0.3rem;
}

.apartment-title {
  font-weight: lighter;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.collapsed {
  height: 75px;
  overflow: hidden;
  margin-bottom: 0rem;
}

.uncollapsed {
  height: 75px;
  overflow: hidden;
  margin-bottom: 0rem;
}

.collapse-text {
  text-decoration: underline;
}

::v-deep .silentbox-item {
  width: 50%;
  max-width: 180px;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
}

::v-deep .silentbox-item img {
  width: 100%;
  border-radius: 0.5rem;
}

::v-deep .silentbox-item:nth-of-type(n + 5) {
  /* Hide all images after the 4th one */
  display: none;
}

::v-deep #silentbox-overlay__embed {
  width: 85%;
}

::v-deep #silentbox-overlay__arrow-buttons .arrow {
  height: 2.5rem;
  width: 2.5rem;
}

::v-deep #silentbox-overlay__arrow-buttons .arrow-next {
  top: 80%;
}

::v-deep #silentbox-overlay__arrow-buttons .arrow-previous {
  top: 80%;
}
</style>
