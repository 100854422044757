<template>
  <b-container fluid class="main-container">
    <b-row class="h-100">
      <b-col md="8" offset-md="2" class="main-col">
        <b-row class="h-100">
          <b-col id="not-found-col" class="text-center my-auto">
            {{ $t("not-found.message") }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "NotFound"
};
</script>

<style scoped>
#not-found-col {
  font-size: 4.5rem;
}
</style>
