<template>
  <b-container id="pagefooter" fluid>
    <b-row class="p-3 pl-md-4">
      <b-col cols="auto" class="mb-2 ">
        <b-link href="tel:+34634564207" v-on:click="phoneClick">
          <b-row>
            <b-col cols="auto">
              <b-img
                :src="images.phone"
                class="contact-icon"
                alt="telefono logo"
              />
            </b-col>
            <b-col class="my-auto contact-text pl-0">
              <p class="m-0">
                +34 634 56 42 07
              </p>
            </b-col>
          </b-row>
        </b-link>
      </b-col>
      <b-col cols="auto" class="mb-2">
        <b-link href="mailto:reservas@lascarolinas.net" v-on:click="mailClick">
          <b-row>
            <b-col cols="auto">
              <b-img :src="images.email" class="contact-icon" alt="mail logo" />
            </b-col>
            <b-col class="my-auto contact-text pl-0">
              <p class="m-0">
                reservas@lascarolinas.net
              </p>
            </b-col>
          </b-row>
        </b-link>
      </b-col>
      <b-col cols="auto" class="mb-2">
        <b-link to="/delta-ebro" v-on:click="locationClick">
          <b-row>
            <b-col cols="auto">
              <b-img
                :src="images.location"
                class="contact-icon"
                alt="location logo"
              />
            </b-col>
            <b-col class="my-auto contact-text pl-0">
              <p class="m-0">
                Calle Las Carolinas, 15, Sant Carles de la Ràpita, Tarragona
              </p>
            </b-col>
          </b-row>
        </b-link>
      </b-col>
      <b-col cols="auto" class="mb-2">
        <b-link
          href="https://www.facebook.com/Apartamentos-Las-Carolinas-421435137868854"
          v-on:click="facebookClick"
        >
          <b-row>
            <b-col cols="auto">
              <b-img
                :src="images.facebook"
                class="contact-icon"
                alt="facebook logo"
              />
            </b-col>
          </b-row>
        </b-link>
      </b-col>
      <b-col cols="auto" class="mb-2">
        <b-link
          href="https://www.instagram.com/apartamentos_las_carolinas/"
          v-on:click="instagramClick"
        >
          <b-row>
            <b-col cols="auto">
              <b-img
                :src="images.instagram"
                class="contact-icon"
                alt="instagram logo"
              />
            </b-col>
          </b-row>
        </b-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-right" align-self="end">
        <p class="mb-2">
          © Copyright Apartamentos Las Carolinas |
          <b-link to="/privacidad" class="policy-link">{{
            $t("footer.privacy")
          }}</b-link>
          |
          <b-link to="/legal" class="policy-link">{{
            $t("footer.legal")
          }}</b-link>
          |
          <b-link to="/cookies" class="policy-link">{{
            $t("footer.cookies")
          }}</b-link>
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "PageFooter",

  data: function() {
    return {
      images: {
        email: require("../assets/pagefooter_email.png"),
        phone: require("../assets/pagefooter_phone.png"),
        location: require("../assets/pagefooter_location.png"),
        facebook: require("../assets/pagefooter_facebook.png"),
        instagram: require("../assets/pagefooter_instagram.png")
      }
    };
  },

  methods: {
    phoneClick: function() {
      this.$gtag.event("PhoneClick", {
        event_category: "Contact",
        event_label: "FooterLink"
      });
    },

    mailClick: function() {
      this.$gtag.event("MailClick", {
        event_category: "Contact",
        event_label: "FooterLink"
      });
    },

    locationClick: function() {
      this.$gtag.event("LocationClick", {
        event_category: "Navigate",
        event_label: "FooterLink"
      });
    },

    facebookClick: function() {
      this.$gtag.event("FacebookClick", {
        event_category: "SocialNetwork",
        event_label: "FooterLink"
      });
    },

    instagramClick: function() {
      this.$gtag.event("InstagramClick", {
        event_category: "SocialNetwork",
        event_label: "FooterLink"
      });
    }
  }
};
</script>

<style scoped>
#pagefooter {
  background-image: url("../assets/footer_background.png");
  background-color: #dee2d6;
  border-top: 2px solid #babeb1;
  font-size: 0.9rem;
  font-weight: normal;
}

.policy-link {
  color: black;
}

.contact-icon {
  height: 2.5em;
}

.contact-text {
  max-width: 15rem;
}

.contact-text p {
  color: black;
  text-decoration: none !important;
}

.contact-text a:hover {
  color: black;
}

a {
  text-decoration: none !important;
}
</style>
