import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import BootstrapVue from "bootstrap-vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueSilentbox from "vue-silentbox";
import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";
import VueJsonLD from "vue-jsonld";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./App.css";
import i18n from "./i18n";

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VueAxios, axios);
Vue.use(VueSilentbox);
Vue.use(
  VueGtag,
  {
    config: { id: "UA-61525001-1" },
    // bootstrap: false,
    appName: "App",
    pageTrackerScreenviewEnabled: true
  },
  router
);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
Vue.use(VueJsonLD);

new Vue({
  router,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
}).$mount("#app");
