import i18n from "@/i18n";
import { extend } from "vee-validate";
import { required, email, alpha_spaces } from "vee-validate/dist/rules";

extend("email", email);
extend("alpha_spaces", alpha_spaces);

// Override the default message.
extend("required", {
  ...required,
  message: () => i18n.tc("booking.form-required-field")
});

extend("requiredPolicy", {
  ...required,
  message: () => i18n.t("booking.form-required-policy")
});
