<template>
  <div id="app">
    <PageHeader class="flex-none" />
    <router-view />
    <PageFooter class="flex-none" />
    <cookie-law v-on:accept="cookieAccepted()" id="cookie-panel">
      <div slot-scope="props" id="props-slot">
        <p class="mb-1 pb-2 text-left">
          {{ $t("main.cookies") }}
          <router-link to="/privacidad">{{ $t("footer.privacy") }}</router-link>
          |
          <router-link to="/cookies">{{ $t("footer.cookies") }}</router-link> |
          <router-link to="/legal">{{ $t("footer.legal") }}</router-link>
        </p>
        <button class="policy-button" id="reject-policy" @click="props.close">
          <span>{{ $t("main.cookies-reject") }}</span>
        </button>
        <button class="policy-button" id="accept-policy" @click="props.accept">
          <span>{{ $t("main.cookies-accept") }}</span>
        </button>
      </div>
    </cookie-law>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import { bootstrap } from "vue-gtag";
import CookieLaw from "vue-cookie-law";

export default {
  name: "App",

  metaInfo: {
    title: "Las Carolinas | Apartamentos de vacaciones en el Delta del Ebro",

    link: [
      { rel: "canonical", href: "https://www.lascarolinas.net" },
      { rel: "apple-touch-icon", href: "/apple-touch-icon.png" },
      {
        rel: "alternate",
        hreflang: "es",
        href: "https://www.lascarolinas.net/es"
      },
      {
        rel: "alternate",
        hreflang: "ca",
        href: "https://www.lascarolinas.net/ca"
      },
      {
        rel: "alternate",
        hreflang: "en",
        href: "https://www.lascarolinas.net/en"
      },
      {
        rel: "alternate",
        hreflang: "fr",
        href: "https://www.lascarolinas.net/fr"
      }
    ],

    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      {
        name: "description",
        content:
          "Apartamentos vacacionales en el Delta del Ebro, ideales para un descanso en familia o una escapada de fin de semana. ¡También con piscina privada!"
      },
      // OpenGraph data (Most widely used)
      { property: "og:title", content: "Apartamentos Las Carolinas" },
      { property: "og:site_name", content: "Las Carolinas" },
      // The list of types is available here: http://ogp.me/#types
      { property: "og:type", content: "website" },
      // Should the the same as your canonical link, see below.
      { property: "og:url", content: "https://www.lascarolinas.net" },
      {
        property: "og:image",
        content: "https://lascarolinas.net/public.jpg"
      },
      // Often the same as your meta description, but not always.
      {
        property: "og:description",
        content:
          "Las Carolinas es un conjunto de apartamentos de alquiler situados en el Delta del Ebro, ideal para unas vacaciones en familias o una escapada de fin de semana."
      },

      // Twitter card
      { name: "twitter:card", content: "summary" },
      { name: "twitter:site", content: "https://www.lascarolinas.net" },
      { name: "twitter:title", content: "Apartamentos Las Carolinas" },
      {
        name: "twitter:description",
        content:
          "Las Carolinas es un conjunto de apartamentos de alquiler situados en el Delta del Ebro, ideal para unas vacaciones en familias o una escapada de fin de semana."
      },
      {
        name: "twitter:image",
        content: "https://lascarolinas.net/public.jpg"
      },

      // Google / Schema.org markup:
      { itemprop: "name", content: "Apartamentos Las Carolinas" },
      {
        itemprop: "description",
        content:
          "Las Carolinas es un conjunto de apartamentos de alquiler situados en el Delta del Ebro, ideal para unas vacaciones en familias o una escapada de fin de semana."
      },
      {
        itemprop: "image",
        content: "https://lascarolinas.net/public.jpg"
      }
    ]
  },

  jsonld: {
    "@context": "http://schema.org",
    "@type": "Hotel",
    image: "https://lascarolinas.net/public.jpg",
    petsAllowed: "True",
    priceRange: "$",
    address: {
      "@type": "PostalAddress",
      addressLocality: "Sant Carles de la Rapita",
      addressRegion: "Spain",
      postalCode: "43540",
      streetAddress: "Calle Las Carolinas, 15"
    },
    amenityFeature: [
      { "@type": "LocationFeatureSpecification", name: "Free Wi-Fi" },
      { "@type": "LocationFeatureSpecification", name: "Free parking" },
      { "@type": "LocationFeatureSpecification", name: "Accessible" },
      { "@type": "LocationFeatureSpecification", name: "Air-conditioned" },
      { "@type": "LocationFeatureSpecification", name: "Pool" },
      { "@type": "LocationFeatureSpecification", name: "Pet-friendly" },
      { "@type": "LocationFeatureSpecification", name: "Kid-friendly" },
      { "@type": "LocationFeatureSpecification", name: "Kitchen in all rooms" }
    ],
    name: "Las Carolinas",
    url: "https://www.lascarolinas.net",
    email: "reservas@lascarolinas.net",
    telephone: "34634564207"
  },

  components: {
    PageHeader,
    PageFooter,
    CookieLaw
  },

  methods: {
    cookieAccepted() {
      //bootstrap().then(gtag => {
      // all done!
      //});
    }
  }
};
</script>

<style src="./App.css"></style>
