<template>
  <b-container fluid id="landing-main">
    <b-row id="main-row" class="my-auto">
      <b-col md="6" offset-md="3" class="my-auto">
        <b-row align-h="end">
          <b-col cols="auto" class="landing-text-box text-right">
            <h1 class="m-0">{{ $t("landing.title") }}</h1>
          </b-col>
        </b-row>
        <b-row align-h="end">
          <b-col class="landing-text-box important-text">
            <h2 class="text-right important-text mb-0 large-line-height">
              {{ $t("landing.subtitle") }}
            </h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-button
              to="/instalaciones"
              class="carolinas-button landing-button important-text"
              v-on:click="knowMoreClick"
              >{{ $t("landing.knowmore-button") }}</b-button
            >
          </b-col>
          <b-col class="text-center">
            <b-button
              to="/booking"
              class="carolinas-button landing-button important-text"
              v-on:click="contactClick"
              >{{ $t("landing.book-button") }}</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Landing",

  methods: {
    knowMoreClick: function() {
      this.$gtag.event("FacilitiesClick", {
        event_category: "HappyPath",
        event_label: "LandingPage"
      });
    },

    contactClick: function() {
      this.$gtag.event("BookingClick", {
        event_category: "HappyPath",
        event_label: "LandingPage"
      });
    }
  }
};
</script>

<style scoped>
#landing-main {
  background-image: url("../assets/landing_background.jpg");
  background-position: center;
  background-size: cover;
  flex: auto;
  flex-direction: row;
  min-height: 100%;
}

#main-row {
  flex-direction: row;
  height: 100%;
}

.landing-text-box {
  background-color: rgba(255, 255, 255, 0.38);
  font-weight: normal;
  margin-bottom: 1em;
  margin-top: 1em;
  padding: 1em;
}

.large-line-height {
  line-height: 1.5;
}

.landing-button {
  padding-left: 2.5em;
  padding-right: 2.5em;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  margin: 1em;
}
</style>
